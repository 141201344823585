/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2018
 * @version 1.4.7
 *
 * Bootstrap Popover Extended - Popover with modal behavior, styling enhancements and more.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
/* Hide popover markup by default */
.popover-x {
    display: none;
    border: 1px solid #ccc;
    /*noinspection CssOverwrittenProperties*/
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popover-x .popover-title {
    border-radius: 5px 5px 0 0;
}

/* Popover & arrow styles for BS4 */
.popover-x.top {
    margin-top: -10px;
}

.popover-x.right {
    margin-left: 10px;
}

.popover-x.bottom {
    margin-top: 10px;
}

.popover-x.left {
    margin-left: -10px;
}

.popover-x > .arrow, .popover-x > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: solid transparent;
}

.popover-x > .arrow {
    border-width: 11px;
}

.popover-x > .arrow:after {
    content: "";
    border-width: 10px;
}

.popover-x.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
}

.popover-x.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}

.popover-x.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0;
}

.popover-x.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
}

.popover-x.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25);
}

.popover-x.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}

.popover-x.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, .25);
}

.popover-x.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff;
}

/* Sizes */
.popover-lg {
    min-width: 480px;
}

.popover-md {
    min-width: 350px;
}

.popover-x .close {
    line-height: 20px;
    outline: none;
}

.popover-x.top-right > .arrow, .popover-x.bottom-right > .arrow {
    left: 90%;
}

.popover-x.top-left > .arrow, .popover-x.bottom-left > .arrow {
    left: 10%;
}

.popover-x.left-top > .arrow, .popover-x.right-top > .arrow {
    top: 10%;
}

.popover-x.left-bottom > .arrow, .popover-x.right-bottom > .arrow {
    top: 90%;
}

.popover-default.bottom > .arrow:after {
    border-bottom-color: #f7f7f7;
}

.popover-primary.bottom > .arrow:after {
    border-bottom-color: #cce5ff;
}

.popover-success.bottom > .arrow:after {
    border-bottom-color: #dff0d8;
}

.popover-danger.bottom > .arrow:after {
    border-bottom-color: #f2dede;
}

.popover-warning.bottom > .arrow:after {
    border-bottom-color: #fcf8e3;
}

.popover-info.bottom > .arrow:after {
    border-bottom-color: #d9edf7;
}

.popover-default.left-top > .arrow:after {
    border-left-color: #f7f7f7;
}

.popover-default.right-top > .arrow:after {
    border-right-color: #f7f7f7;
}

.popover-primary.left-top > .arrow:after {
    border-left-color: #cce5ff;
}

.popover-primary.right-top > .arrow:after {
    border-right-color: #cce5ff;
}

.popover-success.left-top > .arrow:after {
    border-left-color: #dff0d8;
}

.popover-success.right-top > .arrow:after {
    border-right-color: #dff0d8;
}

.popover-danger.left-top > .arrow:after {
    border-left-color: #f2dede;
}

.popover-danger.right-top > .arrow:after {
    border-right-color: #f2dede;
}

.popover-warning.left-top > .arrow:after {
    border-left-color: #fcf8e3;
}

.popover-warning.right-top > .arrow:after {
    border-right-color: #fcf8e3;
}

.popover-info.left-top > .arrow:after {
    border-left-color: #d1ecf1;
}

.popover-info.right-top > .arrow:after {
    border-right-color: #d1ecf1;
}

.popover-default > .popover-title {
    color: #333;
    background-color: #f7f7f7;
}

.popover-primary > .popover-title {
    color: #004085;
    background-color: #cce5ff;
    border-bottom: 1px solid #b8daff;
}

.popover-success > .popover-title {
    color: #3c763d;
    background-color: #dff0d8;
    border-bottom: 1px solid #d6e9c6;
}

.popover-info > .popover-title {
    color: #0c5460;
    background-color: #d1ecf1;
    border-bottom: 1px solid #bee5eb;
}

.popover-warning > .popover-title {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-bottom: 1px solid #faebcc;
}

.popover-danger > .popover-title {
    color: #a94442;
    background-color: #f2dede;
    border-bottom: 1px solid #ebccd1;
}

.popover-footer {
    padding: 4px;
    background-color: #fbfbfb;
    text-align: right;
    border-top: 1px solid #ebebeb;
    border-radius: 0 0 5px 5px;
}

.popover-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 4px;
}

.popover-footer .btn-group .btn + .btn {
    margin-left: -1px;
}

.popover-footer .btn-block + .btn-block {
    margin-left: 0;
}

.popover-x.has-footer.top > .arrow:after, .has-footer.popover-x.top > .arrow:after {
    border-top-color: #fbfbfb;
}

.popover-x.has-footer.left-bottom > .arrow:after {
    border-top-color: transparent;
    border-left-color: #fbfbfb;
}

.popover-x.has-footer.right-bottom > .arrow:after {
    border-top-color: transparent;
    border-right-color: #fbfbfb;
}

.popover-loading {
    padding: 30px;
    /* background: url('../img/loading.gif') center center; */
}

/* hide modal backdrop */
.popover-x-body .modal-backdrop {
    opacity: 1;
    z-index: 1;
    background: transparent;
    display: none;
}

.popover-x-body.modal-open {
    overflow-y: auto;
}

/* Bootstrap 4.x overrides */
.popover-x.is-bs4.right > .arrow {
    left: -0.99rem;
}

.popover-x.is-bs4.left > .arrow {
    right: -0.99rem;
}